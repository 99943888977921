import { Component, Input, OnInit } from '@angular/core';
import { iBisBaseComponent, iBisLanguageService, iBisUIService } from '@dohu/ibis-common';
import { QuotationService } from '../../../app/quotation/quotation.service';
import { DataService } from '../../../app/services';
import { QuotationItemEditService } from './quotation-item-edit.service';

@Component({
	selector: 'app-quotation-item-edit',
	templateUrl: './quotation-item-edit.component.html',
	styles: ['.total-price{ text-align: right;} .total-price span{margin-right: 90px} .total-price>.total{font-weight: 700; font-size:15px;} .price-vat-info{ font-size: 20px; color: red; font-weight: 500;}']
})
export class QuotationItemEditComponent extends iBisBaseComponent {

	@Input() rfqItems: any;
	@Input() dsPaymentMethods: any[] = [];
	@Input() readOnly: boolean = false;
	@Input() byMaterial: boolean = true;
	@Input() currency: any[] = [];
	@Input() priceItemSource!: any[];
	priceSource!: any[];
	constructor(public override edit: QuotationItemEditService, lg: iBisLanguageService, iui: iBisUIService,
		public ds: DataService, public qs: QuotationService) {
		super(edit, lg, iui, <any>null);
	}

	materialValExpr = (ev: any) => {
		if(!ev) return;
		return this.byMaterial ? ev.m_id : ev.ris_id;
	}

	materialDispExpr = (ev: any) => {
		if(!ev) return;
		return this.byMaterial ? ev.m_name : `${ev.ris_rnNo} ${ev.ris_rnPosNo} ${ev.m_name}`;
	}

	onPriceChanged(ev: any) {
		const ds = ev.component.getDataSource().items();
		if (ds && ds.length) {
			this.edit.model.itemPrice = 0;
			ds.forEach((element: any) => {
				this.edit.model.itemPrice += element.value;
			});
		}
	}
	calcTotal(nr: number) {
		return Math.round(nr * 100) / 100;
	}

	onItemChanged = (ev: any) => {
		if (ev && ev.selectedItem) {
			this.edit.model.typeId = ev.selectedItem.m_typeId;
			const exchRate = this.qs.currency.find((x: any) => x.id == ev.selectedItem.ri_currencyId);
			this.edit.model.currencyName = exchRate ? exchRate.name : '';
			this.edit.model.quantity = ev.selectedItem.ris_quantity;
			this.edit.model.minQuantity = ev.selectedItem.ris_quantity;
			this.edit.model.uom = ev.selectedItem.uom_description;
			this.edit.model.itemPrice = this.edit.model.itemPrice || 0;
			this.edit.model.paymentDays = this.edit.model.paymentDays || 0;
			this.edit.model.deliveryDays = this.edit.model.deliveryDays || 0;
			if (this.qs.party && this.qs.party.enLanguage && ev.selectedItem.uom_descriptionEng) {
				this.edit.model.uom = ev.selectedItem.uom_descriptionEng;
			}

			this.priceSource = this.edit.model.typeId == 1 ? this.qs.materialPrice : this.qs.servicePrice;
			if (!(this.priceSource && this.priceSource.length) && this.priceItemSource && this.priceItemSource.length) {
				this.priceSource = this.priceItemSource;
			}
			if (this.qs.quotePrice && this.qs.quotePrice.length && this.edit.model.id) {
				this.edit.quotePriceItem = this.qs.quotePrice.filter((x: any) => x.quoteId == this.edit.model.id);
			} else if (!this.priceItemSource || !this.priceItemSource.length) {
				this.edit.quotePriceItem = [];
				this.priceSource.forEach((item: any) => {
					if (item.orderIndex <= 5) {
						this.edit.quotePriceItem.push({ itemId: item.id, value: 0 });
					}
				});
			}
		}
	}

	onFormUpdate(ev: any) {
		if (this.edit.model && this.edit.model.quantity && this.edit.model.itemPrice) {
			this.edit.model.total = Math.round(this.edit.model.quantity * this.edit.model.itemPrice * 100) / 100;
			if (this.edit.model.currencyValue) {
				this.edit.model.total = Math.round(this.edit.model.total * this.edit.model.currencyValue * 100) / 100;
			}
		}
	}

	priceCustomize = (cellInfo: any) => {
		return (cellInfo.value ?? '') + (this.edit?.model?.currencyName ?? '');
	}

	onCurrencyChanged = (ev: any) => {
		if (ev.selectedItem) {
			this.edit.model.currencyId = ev.selectedItem.id;
			this.edit.model.currencyName = ev.selectedItem.name;
		}
	}

	onQuoteItemPrepared(ev: any) {
		ev.toolbarOptions.items.unshift({
			location: 'before',
			text: this.L('TABLE_PRICE')
		});
	}

	onInitializedFormEv(ev: any) {
		this.edit.formInst = ev.component;
	}
}
