import { AfterViewInit, Component, OnDestroy } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { iBisAuthService } from '@dohu/ibis-auth';
import { iBisViewComponent, iBisLanguageService, iBisUIService } from '@dohu/ibis-common';
import notify from 'devextreme/ui/notify';
import { confirm, alert } from 'devextreme/ui/dialog';
import { DataService } from '../../../app/services';
import { RfqService } from '../rfq.service';
import { RfqEditService } from './rfq-edit.service';
import { OrderLineEditService } from 'src/app/order/order-edit/order-line-edit/order-line-edit.service';
import { RfqEditOrderSelectService } from './rfq-edit-order-select/rfq-edit-order-select.service';
import { RfqEditStatusService } from './rfq-edit-status/rfq-edit-status.service';
import { SendNotificationService } from 'src/app/common/send-notification/send-notification.service';
import { ModalFileService } from 'src/app/common/modal-file/modal-file.service';
import { OrderEditService } from 'src/app/order/order-edit/order-edit.service';

@Component({
  selector: 'app-rfq-edit',
  templateUrl: './rfq-edit.component.html',
  styleUrls: ['./rfq-edit.component.scss']
})
export class RfqEditComponent extends iBisViewComponent implements OnDestroy, AfterViewInit {
  collapsed: boolean = false;

  constructor(public override edit: RfqEditService, public rfq: RfqService, lg: iBisLanguageService,
    iui: iBisUIService, private router: Router, auth: iBisAuthService, route: ActivatedRoute,
    public ds: DataService, public sts: RfqEditStatusService, public sns: SendNotificationService,
    public order: OrderEditService, public redOrdSel: RfqEditOrderSelectService,
    public modalFile: ModalFileService) {
    super(edit, lg, iui, route);
    this.ui.formatDateTime

    route.params.subscribe((qp: any) => {
      if (qp && qp.id && qp.id.length == 36) {
        this.edit.initData(qp.id);
      } else {
        notify(this.L('RFQ_NOT_FOUND'), 'error', 5000);
        this.router.navigateByUrl('rfquotation');
      }
    })
  }

  override ngAfterViewInit(): void {
    super.ngAfterViewInit();
    this.ds.resetFileExtra();
  }

  ngOnDestroy() {
    this.edit.model = this.edit.createDefault();
  }

  get buyerName() {
    let b = this.rfq.buyer.find(x => x.u_id == this.edit.model.userId);
    return b ? b.u_fullName : "Necunoscut";
  }

  goBack() {
    this.router.navigateByUrl('rfquotation');
  }

  onCollapsed(ev: any) {
    this.collapsed = !this.collapsed;
  }

  generateOrder() {
    this.redOrdSel.showPopup({
      rfqId: this.edit.model.id,
      rfqNumber: this.edit.model.refNumber,
      byMaterial: this.edit.model.byMaterial
    }, true).then(resp => {
      if (resp && resp.orderId) {
        if (resp.redirect) {
          this.order.showPopup(resp.orderId, true);
        } else {
          let alertMsg = resp.sendToSAP ? this.L('ORDER_X_HAS_BEEN_CREATED_SAP') : this.L('ORDER_X_HAS_BEEN_CREATED');
          alertMsg = alertMsg.replace('__NR_ORDER__', resp.refNumber)
            .replace('__RFQ_NUMBER__', resp.rfqNumber).replace('__SUPPLIER__', resp.partyName);
          alert(alertMsg, this.L('ALERT')).then(() => {
            // this.edit.initOrderData(this.edit.model.id);
          })
        }
      }
    }, err => {
      notify('ERROR_GENERATE_ORDER', 'error', 3000);
    })
  }

  statusCheck(status: number[], orFlowType?: { flowType: number, statusId: number }[]) {
    var flowTypeCond = false;
    if (this.edit.model && orFlowType && orFlowType.length) {
      orFlowType.forEach(el => {
        if (flowTypeCond == false) {
          flowTypeCond = el.statusId == this.edit.model.statusId && el.flowType == this.edit.model.flowType;
        }
      });
    }
    return this.edit.model && (status.indexOf(this.edit.model.statusId) > -1 || flowTypeCond);
  }

  public updateRFQ(statusId: number) {
    switch (statusId) {
      case 5:
      case 12:
        this.sts.title = statusId == 5 ? this.L('PUBLISH_REQUEST') : this.L('COMMERCIAL_PUBLISH');
        this.sts.showPopup({
          id: this.edit.model.id,
          statusId: statusId,
          oldDueDate: new Date(this.edit.model.dueDate),
          dueDate: new Date(this.edit.model.dueDate),
          partyNotes: this.edit.model.partyNotes,
          partyNotesEng: this.edit.model.partyNotesEng
        }).then((resp) => {
          if (resp) {
            notify(this.L('SUCCESS'), 'success', 3000);
            this.edit.initData(this.edit.model.id);
          }
        });
        break;
      case 10:
        this.sts.title = this.L('TECHNICAL_APPROVE');
        this.sts.showPopup({
          id: this.edit.model.id,
          statusId: statusId,
          oldDueDate: new Date(this.edit.model.dueDate),
          dueDate: new Date(new Date().setDate(new Date().getDate() + 2)),
          partyNotes: this.edit.model.partyNotes
        }).then((resp) => {
          if (resp) {
            notify(this.L('SUCCESS'), 'success', 3000);
            this.edit.initData(this.edit.model.id);
          }
        })
        break;
      case 15:
        confirm(this.L('CONFIRM_COM_RFQ'), this.L('ALERT')).then(resp => {
          if (resp) {
            this.edit.saveNextStatus(statusId);
          }
        });
        break;
      case 18:
        confirm(this.L('CONFIRM_ANALYZE'), this.L('ALERT')).then(resp => {
          if (resp) {
            this.edit.saveNextStatus(statusId);
          }
        });
        break;
      case -1:
        confirm(this.L('CONFIRM_CANCEL_RFQ'), this.L('ALERT')).then(resp => {
          if (resp) {
            this.edit.saveNextStatus(statusId);
          }
        });
        break;
      case 20:
        if (this.edit.model.statusId != 0) {
          this.edit.rfqItemQuote.load().then((resp: any) => {
            let totalWinnerItem = 0;
            let totalRNItem = 0;
            for (const item of resp) {
              if (item.items) {
                totalRNItem += item.items.length;
                for (const inq of item.items) {
                  totalWinnerItem += inq.items.filter((x: any) => x.riq_isWinner).length;
                }
              }
            }
            let msg = this.L("CONFIRM_FINALIZE_RFQ_NEW");
            if (totalWinnerItem < totalRNItem) {
              msg = this.L("CONFIRM_FINALIZE_RFQ");
            }
            confirm(msg, this.L('ALERT')).then(resp => {
              if (resp) {
                this.edit.saveNextStatus(statusId);
              }
            })
          })
        } else {
          confirm(this.L('CONFIRM_FINALIZE_RFQ_NEW'), this.L('ALERT')).then(resp => {
            if (resp) {
              this.edit.saveNextStatus(statusId);
            }
          })
        }
        break;
      default:
        this.edit.saveNextStatus(statusId);
        break;
    }
  }

}
